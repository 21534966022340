import { EditorSDK } from '@wix/platform-editor-sdk';
import { monitoredTransactionFactory } from '../../../utils/monitoring';
import { shouldOptimizeVerticalDeletion } from '../../../utils/experiments';
import { MembersAreaPublicApi } from '../types';
import { openAddTabsPanel } from '../../wrappers/panels';
import enforceSequentiality from '../../enforceSequentiality';
import { getIsResponsiveEditor } from '../../services/applicationState';
import { navigateToMembersAreaPage } from './services/navigation';
import { maybeShowProfileCard } from './services/layout';
import { getProfileType, setProfileType } from './services/blog-writer';
import { modifyPages, modifyPagesAndOpenManagePages } from './services/general-settings';
import { isAppSectionInstalled, isProfilePageBobValid, navigateToSection } from './services/members-area-page';
import {
  deleteVerticalApps,
  getDefinitions,
  getRegisteredApps,
  installApps,
  installRegisteredApps,
  registerMembersAreaApps,
} from './integration';

const initPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  // TODO: check if BoB is valid via bob public api - isProfilePageBobValid
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const sequentialMonitoredTransaction = <T>(name: string, action: () => Promise<T>) => {
    return enforceSequentiality(name, () => monitoredTransaction(name, action)) as unknown as Promise<T>;
  };

  return {
    getRegisteredApps: () => {
      return getRegisteredApps(editorSDK);
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      const action = () => registerMembersAreaApps(editorSDK, applications, verticalAppDefId, applicationsOptions);
      return monitoredTransaction('editorAPI.v2.registerMembersAreaApps', action);
    },
    installRegisteredApps: (verticalAppDefId) => {
      const action = () => installRegisteredApps(editorSDK, verticalAppDefId);
      return sequentialMonitoredTransaction('editorAPI.v2.installRegisteredApps', action);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      const action = async () => {
        const definitions = await getDefinitions(editorSDK, applications);
        await installApps(editorSDK, definitions, shouldNavigate);
        return maybeShowProfileCard(editorSDK, definitions);
      };

      return sequentialMonitoredTransaction('editorAPI.v2.addApplications', action);
    },
    handleVerticalDeletion: (verticalAppDefId) => {
      const action = async () => {
        if (await shouldOptimizeVerticalDeletion()) {
          return deleteVerticalApps(editorSDK, verticalAppDefId);
        }

        await navigateToMembersAreaPage(editorSDK);
        return deleteVerticalApps(editorSDK, verticalAppDefId);
      };
      return monitoredTransaction('editorAPI.v2.handleVerticalDeletion', action);
    },
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (profileType) => {
      return monitoredTransaction('editorAPI.v2.setProfileType', () => setProfileType(editorSDK, profileType));
    },
    modifyPages: (options) => {
      return monitoredTransaction('editorAPI.v2.modifyPages', () => modifyPages(editorSDK, options));
    },
    modifyPagesAndOpenManagePages: (options, referralInfo) => {
      return monitoredTransaction('editorAPI.v2.modifyPagesAndOpenManagePages', () =>
        modifyPagesAndOpenManagePages(editorSDK, options, referralInfo),
      );
    },
    refreshPageState: () => {
      return editorSDK.application.reloadManifest();
    },
    _getIsResponsiveEditor: () => {
      return Promise.resolve(getIsResponsiveEditor());
    },
    openAddTabsPanel: (referralInfo) =>
      openAddTabsPanel({ editorSDK, eventPayload: { pageRef: undefined, referralInfo } }),
    getIsMembersAreaV2Context: () => Promise.resolve(true),
    isAppSectionInstalled: (sectionData) => isAppSectionInstalled(editorSDK, sectionData),
    navigateToSection: ({ widgetId }) =>
      monitoredTransaction('editorAPI.v2.navigateToSection', () => navigateToSection(editorSDK, widgetId)),
    openGeneralSettingsPanel: async () => {
      throw new Error('[MAV2] PublicAPI.openGeneralSettingsPanel is not supported');
    },
    getGeneralSettingsPanelData: () => {
      throw new Error('[MAV2] PublicAPI.getGeneralSettingsPanelData is not supported');
    },
    getMemberPrivacySettings: () => {
      throw new Error('[MAV2] PublicAPI.getMemberPrivacySettings is not supported');
    },
    getMembersPageRef: (page) => {
      throw new Error('[MAV2] PublicAPI.getMembersPageRef is not implemented');
    },
    removeMembersAreaPage: (pageId, appDefinitionId) => {
      throw new Error('[MAV2] PublicAPI.removeMembersAreaPage is not implemented');
    },
    setHorizontalLayout: (pwHeight) => {
      throw new Error('[MAV2] PublicAPI.setHorizontalLayout is not implemented');
    },
    setSidebarLayout: () => {
      throw new Error('[MAV2] PublicAPI.setSidebarLayout is not implemented');
    },
    addCustomPage: (isPrivate) => {
      throw new Error('[MAV2] PublicAPI.addCustomPage is not implemented');
    },
    refreshMembersAreaApps: () => {
      throw new Error('[MAV2] PublicAPI.refreshMembersAreaApps is not implemented');
    },
    setProfileWidgetHeight: (height) => {
      throw new Error('[MAV2] PublicAPI.setProfileWidgetHeight is not implemented');
    },
    /** @deprecated */
    refreshRouters: () => {
      throw new Error('[MAV2] PublicAPI.refreshRouters is not implemented');
    },
    /** @deprecated */
    registerAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.registerAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    getAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.getAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    installAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.installAdditionalWidgets is not implemented');
    },
  };
};

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const getPublicAPI = async () => {
    const publicAPI = initPublicAPI(editorSDK);

    if (!(await isProfilePageBobValid(editorSDK))) {
      throw new Error('[MA Umbrella - Public API V2] Member Page or Widget is missing');
    }

    return publicAPI;
  };
  return {
    getRegisteredApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRegisteredApps();
    },
    registerMembersAreaApps: async (applications, verticalAppDefId, applicationsOptions) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.registerMembersAreaApps(applications, verticalAppDefId, applicationsOptions);
    },
    installRegisteredApps: async (verticalAppDefId, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installRegisteredApps(verticalAppDefId, options);
    },

    addApplications: async (applications, shouldNavigate, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addApplications(applications, shouldNavigate, options);
    },
    handleVerticalDeletion: async (verticalAppDefId) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.handleVerticalDeletion(verticalAppDefId);
    },
    getProfileType: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getProfileType();
    },
    setProfileType: async (profileType) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileType(profileType);
    },
    modifyPages: async (options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPages(options);
    },
    modifyPagesAndOpenManagePages: async (options, referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPagesAndOpenManagePages(options, referralInfo);
    },
    refreshPageState: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshPageState();
    },
    _getIsResponsiveEditor: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI._getIsResponsiveEditor();
    },
    openAddTabsPanel: async (referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openAddTabsPanel(referralInfo);
    },
    getIsMembersAreaV2Context: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getIsMembersAreaV2Context();
    },
    isAppSectionInstalled: async (sectionData) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.isAppSectionInstalled(sectionData);
    },
    navigateToSection: async (section) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(section);
    },
    openGeneralSettingsPanel: async (referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openGeneralSettingsPanel(referralInfo);
    },
    getGeneralSettingsPanelData: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getGeneralSettingsPanelData();
    },
    getMemberPrivacySettings: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMemberPrivacySettings();
    },
    getMembersPageRef: async (page) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersPageRef(page);
    },
    removeMembersAreaPage: async (pageId, appDefinitionId) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeMembersAreaPage(pageId, appDefinitionId);
    },
    setHorizontalLayout: async (pwHeight) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setHorizontalLayout(pwHeight);
    },
    setSidebarLayout: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSidebarLayout();
    },
    addCustomPage: async (isPrivate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addCustomPage(isPrivate);
    },
    refreshMembersAreaApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshMembersAreaApps();
    },
    setProfileWidgetHeight: async (height) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileWidgetHeight(height);
    },
    refreshRouters: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshRouters();
    },
    registerAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.registerAdditionalWidgets();
    },
    getAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getAdditionalWidgets();
    },
    installAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installAdditionalWidgets();
    },
  };
};
