import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { getIsClassicEditor } from '../../../services/applicationState';
import { hideProfileCard } from '../services/members-area-page';

import {
  disableRefreshingDuringInstallation,
  shouldNotInstallProfileCard,
  shouldNotWaitNavigation,
} from '../../../../utils/experiments';

import {
  monitoredAddGlobalController,
  monitoredAddLoginMenus,
  monitoredAddMembersAreaPage,
  monitoredAddMembersSubMenu,
  monitoredAddMyAccountMenuItemToMembersAreaMenus,
  monitoredNavigateToMembersPage,
  monitoredRefreshMemberPage,
  monitoredUninstallMembersArea,
} from './monitored-methods';

const isStandaloneInstallation = (options: EditorReadyOptions) => !options.origin.info?.appDefinitionId;

const shouldNavigateAfterInstallation = async (options: EditorReadyOptions) => {
  // Studio will navigate itself while installing
  if (!getIsClassicEditor()) {
    return false;
  }

  // Navigate to MA only when standalone / no other app invoked the installation
  return isStandaloneInstallation(options);
};

export const installMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  try {
    await monitoredAddGlobalController(editorSDK);
    await Promise.all([
      monitoredAddMembersSubMenu(editorSDK),
      monitoredAddLoginMenus(editorSDK, options),
      monitoredAddMembersAreaPage(editorSDK),
    ]);

    if (await shouldNotInstallProfileCard()) {
      await monitoredAddMyAccountMenuItemToMembersAreaMenus(editorSDK, options);
    } else {
      await Promise.all([
        monitoredAddMyAccountMenuItemToMembersAreaMenus(editorSDK, options),
        hideProfileCard(editorSDK),
      ]);
    }

    if ((await disableRefreshingDuringInstallation()) && isStandaloneInstallation(options)) {
      await monitoredRefreshMemberPage(editorSDK);
    }

    if (await shouldNavigateAfterInstallation(options)) {
      if (await shouldNotWaitNavigation()) {
        // Not awaiting, for a performance
        monitoredNavigateToMembersPage(editorSDK);
      } else {
        await monitoredNavigateToMembersPage(editorSDK);
      }
    }
  } catch (e) {
    await monitoredUninstallMembersArea(editorSDK);
    throw e;
  }
};
