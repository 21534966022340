import { EditorSDK } from '@wix/platform-editor-sdk';

import { getGlobalMembersAreaController } from '../../../wrappers/controllers';
import { getMenuById, getMenuItems } from '../../../wrappers/menus';
import { MENU_IDS } from '../../../constants';
import { shouldUseNewRoutesService } from '../../../../utils/experiments';
import { isProfilePageBobValid } from './members-area-page';

type InstallationSuccessState = { success: true } | { success: false; error: Error };

enum InstallationError {
  ControllerConfig = 'controller config is invalid',
  MembersMenu = 'members menu is invalid',
  LoginMenu = 'login menu is invalid',
  MemberPage = 'member page or widget is missing',
}

const getUnsuccessfulInstallationState = (message: InstallationError) => {
  return { success: false, error: new Error(message) };
};

const isControllerConfigValid = async (editorSDK: EditorSDK) => {
  const useNewRoutesService = await shouldUseNewRoutesService();
  if (useNewRoutesService) {
    return true;
  }

  try {
    const controllerConfig = await getGlobalMembersAreaController(editorSDK);
    return !!(controllerConfig?.isMembersAreaV2 !== undefined && controllerConfig.routes?.length);
  } catch (e) {
    return false;
  }
};

const isMembersMenuValid = async (editorSDK: EditorSDK) => {
  try {
    const membersMenuItems = await getMenuItems({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID });
    return !!membersMenuItems.length;
  } catch (e) {
    return false;
  }
};

const isLoginMenuValid = async (editorSDK: EditorSDK) => {
  try {
    const loginMenu = await getMenuById({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID });
    return !!loginMenu;
  } catch (e) {
    return false;
  }
};

const validInstallationRules = [
  { validate: isControllerConfigValid, error: InstallationError.ControllerConfig },
  { validate: isMembersMenuValid, error: InstallationError.MembersMenu },
  { validate: isLoginMenuValid, error: InstallationError.LoginMenu },
  { validate: isProfilePageBobValid, error: InstallationError.MemberPage },
];

export const getInstallationSuccessState = async (editorSDK: EditorSDK): Promise<InstallationSuccessState> => {
  const validationResults = await Promise.all(validInstallationRules.map((rule) => rule.validate(editorSDK)));

  for (const index in validationResults) {
    if (!validationResults[index]) {
      return getUnsuccessfulInstallationState(validInstallationRules[index].error);
    }
  }

  return { success: true };
};

export const verifyMembersAreaInstallation = async (editorSDK: EditorSDK) => {
  const installationState = await getInstallationSuccessState(editorSDK);

  if (!installationState.success) {
    throw installationState.error;
  }
};
